export default {
    init() {


        // $(window).scroll(function() {
        //     var scroll = $(window).scrollTop();
        //     let $bannerHeight = $('.banner__main .banner').height() / 5;
        //
        //     if (scroll > $bannerHeight) {
        //         $('body').addClass('logo__show');
        //     } else {
        //         $('body').removeClass('logo__show');
        //     }
        // });

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
