$(document).ready(function() {

    // Slick slider banner
    if (!$('body').hasClass('front-page-data')) {
        $('.banner__slider').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            arrows: false,
            dots: false,
            fade: true,
            speed: 900,
            infinite: true,
        });
    }

});
