/* eslint-disable */
export const templateTeam = {
    init() {
        // JavaScript to be fired on all pages

        $(function () {
            $('.team__title').matchHeight();
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

