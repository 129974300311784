export default {
    init: function () {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        $('.sf-level-0').click(function() {
            $('html, body').animate({
                scrollTop: $('.row__ajax').offset().top - 200,
            }, 1000)
        });

        $('.team__slider__inner').slick({
            dots: false,
            arrows: true,
            prevArrow: $('.team__slider__arrow--prev'),
            nextArrow: $('.team__slider__arrow--next'),
            infinite: true,
            autoplay: true,
            fade: true,
            autoplaySpeed: 5000,
        });


        $('.team__slider__item').each(function () {
            if ($(this).find('.team__slider__person').length < 2) {
                $(this).addClass('team__slider__one');
            }
        })

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('.slick-slider').css('opacity', '1');

        // Version 2

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            let $bannerHeight = $('.banner__main .banner').height() / 5;

            if (scroll > $bannerHeight) {
                $('body').addClass('logo__gone');
                $('body').addClass('logo__show');
                $('.header').addClass('header__fixed');
            } else {
                $('body').removeClass('logo__gone');
                $('body').removeClass('logo__show');
                $('.header').removeClass('header__fixed');
            }
        });

        $('#jump__to__what').on('click', function () {
            $('html, body').animate({
                scrollTop: $('.projecten__what').offset().top - 200,
            }, 1000)
        })
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
    };
